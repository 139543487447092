<template>
    <div
        v-if="props.visible"
        class="w-screen z-[9999] p-2 h-screen bg-black bg-opacity-60 fixed left-0 top-0 flex justify-center items-center"
    >
        <transition name="slide-fade">
            <div v-show="props.loaded" ref="target" class="modal">
                <div class="title">
                    <div>{{ props.title }}</div>
                    <div @click="$emit('close')">
                        <span class="fa fa-close"></span>
                    </div>
                </div>
                <slot></slot>
            </div>
        </transition>
    </div>
</template>
<script setup>
import { onClickOutside } from "@vueuse/core";
import { ref, watch } from "vue";

const target = ref(null);

let props = defineProps({
    visible: { required: true, type: Boolean },
    loaded: { required: true, type: Boolean },
    title: { required: true, type: String },
});

let emit = defineEmits(["close"]);

onClickOutside(target, (event) => emit("close"));
</script>

<style scoped>
.modal {
    @apply bg-white rounded shadow max-w-2xl max-h-screen min-h-32 min-w-32 overflow-scroll;
}
.title {
    @apply border-b text-3xl p-4 flex justify-between;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
</style>
